.container {
  background-color: var(--primary-light);
  color: var(--secondary);
  display: flex;
  text-transform: uppercase;
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.15rem;
  font-weight: 700;
  transition: 0.3s;
}

.container:hover {
  background-color: var(--primary);
  cursor: pointer;
  text-decoration: none;
  color: var(--secondary);
}
