.question_small {
  font-weight: 700;
  font-size: 14px;
}

.select_container {
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(240, 240, 240, 0.2);
  text-align: center;
  transition: background-color 0.3s;
}

.select_container:hover {
  cursor: pointer;
  background-color: rgba(220, 220, 220, 0.4);
}

.select_header {
  font-weight: 700;
  line-height: 1.2;
  font-size: 20px;
}

.selected {
  background-color: rgba(68, 165, 255, 0.2) !important;
  border: 2px solid rgb(68, 165, 255);
}

.strategy {
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid white;
  margin: -1px 0 0 -1px;
}

.strategy:hover {
  background-color: rgba(240, 240, 240, 0.2);
}

.selected_strat {
  background-color: rgba(68, 165, 255, 0.2) !important;
}

@media (max-width: 720px) {
  .select_container {
    width: 100%;
    margin: 5px 0;
  }
  .strategy {
    width: 100%;
  }
}
