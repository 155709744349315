/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 50px;
  height: 50px;
  padding: 8px;
  border-radius: 30px;
  left: 25px;
  top: 25px;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
}

.bm-burger-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--primary);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20d%3D%22M%200%20256%20L%20512%20256%20M%20256%200%20L%20256%20512%22%20stroke%3D%22rgba(53, 99, 50, 0.6)%22%20stroke-width%3D%221.01px%22%20vector-effect%3D%22non-scaling-stroke%22%20stroke-linecap%3D%22square%22%20%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(
      152deg,
      rgba(27, 25, 38, 0.502) 100%,
      rgba(62, 97, 140, 0.502) 100%
    );
  background-size: 26px;
  background-position: center;
  background-repeat: repeat;
  background-color: black;

  padding: 2.5em 0 0 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  /* padding: 0.8em; */
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 720px) {
  .bm-burger-button {
    background-color: var(--secondary);
    top: 10px;
    left: 10px;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
